.curriculuminfo-container{
    --mask:
    radial-gradient(107.33px at 50% 144.00px,#000 99%,#0000 101%) calc(50% - 96px) 0/192px 100%,
    radial-gradient(107.33px at 50% -96px,#0000 99%,#000 101%) 50% 48px/192px 100% repeat-x;
    -webkit-mask: var(--mask);
          mask: var(--mask);
    margin-top: -4%;
    background-color: #f9f9f9;
    background-image:  radial-gradient(#ADCCC4 1.6500000000000001px, transparent 1.6500000000000001px), radial-gradient(#ADCCC4 1.6500000000000001px, #f9f9f9 1.6500000000000001px);
    background-size: 66px 66px;
    background-position: 0 0,33px 33px;
    padding-bottom: 10rem;
}

.info-list{
    width: 80%;
    padding: 2em;
    display: grid;
    grid-gap: 3em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: auto;
    justify-content: center;
}

.info{
    margin-top: 30%;
    height: 600px;
    text-align: center;
    height: fit-content;
}

.info h2{
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 10px;
    filter: drop-shadow(7px 7px 7px #00000064);
    -webkit-filter: drop-shadow(7px 7px 7px #00000064);
}

.info img{
    height: 230px;
    filter: drop-shadow(7px 7px 7px #00000081);
    -webkit-filter: drop-shadow(7px 7px 7px #00000081);
}

.info .frogstreet-logo{
    height: 300px;
    margin-bottom: -45px;
}

.info .logohead{
    height: 150px;
}

.info h3{
    line-height: 1.5;
    font-size: 18px;
}

.frogstreet-video{
    text-align: center;
    margin-top: -100px;
    filter: drop-shadow(7px 7px 7px #00000081);
    -webkit-filter: drop-shadow(7px 7px 7px #00000081);
}

.montessori-program{
    margin: auto;
    text-align: center;
    width: 50%;
    margin-bottom: 10rem;
}

.monti_logo{
    height: 280px;
    filter: drop-shadow(7px 7px 7px #00000081);
    -webkit-filter: drop-shadow(7px 7px 7px #00000081);
}

.montessori-program h3{
    margin: auto;
    font-size: 18px;
    width: 80%;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */

@media screen and (max-width: 1024px){
    .curriculuminfo-container{
        margin-top: -8%;
    }

    .info-list{
        grid-template-columns: 1fr;
        row-gap: 0%;
        margin-bottom: 250px;
    }
    .info{
        height: 250px;
    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */

@media screen and (max-width: 600px){
    .curriculuminfo-container{
        margin-top: -18%;
    }

    .info-list{
        margin-bottom: 15%;
    }

    .info img{
        height: 160px
    }

    .info .frogstreet-logo{
        height: 200px;
    }

    .info .logohead{
        height: 100px;
    }

    .info h3{
        line-height: 1.5;
        font-size: 16px;
    }

    .frogstreet-video{
        display: none;
    }

    .info h2{
        font-size: 22px;
    }
    
    .monti_logo{
        margin-top: 25px;
        height: 180px;
    }
    
    .montessori-program h3{
        width: 100%;
        margin-bottom: -12rem;
    }
}